<template>
  <div class="c-select">
    <VueTimepicker
      :value="inputedVal"
      format="HH:mm"
      hour-label="時"
      minute-label="分"
      v-model="inputedVal"
      placeholder="00:00"
      input-class="time-picker"
      @close="onSelectedHandler"
    />
    <span class="c-select__icon"></span>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";

export default {
  components: {
    VueTimepicker,
  },
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    validation: {
      type: Function,
    },
  },
  data() {
    return {
      inputedVal: this.value ? this.value : "",
      isValid: false,
    };
  },
  watch: {
    value() {
      this.inputedVal = this.value;
    },
    inputedVal() {
      if (!this.inputedVal) this.$emit("update:value", "");
      this.validationSet();
    },
  },
  methods: {
    changeHandler() {
      this.isValid = this.validation(this.inputedVal);
    },
    validationSet() {
      this.changeHandler();
      this.$emit("update:isValid", this.isValid);
    },
    onSelectedHandler() {
      this.$emit("update:value", this.inputedVal);
    },
  },
  mounted() {
    this.validationSet();
  },
};
</script>
